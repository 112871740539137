import React from "react";

const ContactFormTwo = () => {
  return (
    <>
      <section
        className="contact-us-form pt-60 pb-120"
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Talk to Our Consultants</h2>
                <p>
                  Ready to transform your business? Our team of experts is here
                  to help. Contact us to learn more about our innovative
                  solutions.
                </p>
              </div>
              <form action="#" className="register-form">
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="firstName" className="mb-1">
                      First name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        required
                        placeholder="First name"
                        aria-label="First name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <label htmlFor="lastName" className="mb-1">
                      Last name
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last name"
                        aria-label="Last name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="phone" className="mb-1">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        required
                        placeholder="Phone"
                        aria-label="Phone"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="mb-1">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder="Email"
                        aria-label="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourMessage" className="mb-1">
                      Message <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <textarea
                        className="form-control"
                        id="yourMessage"
                        required
                        placeholder="How can we help you?"
                        style={{ height: "120px" }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                  Get in Touch
                </button>
              </form>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="contact-us-img">
                <img
                  src="https://lh3.googleusercontent.com/VaQKCMpjpvc8dOVJjXM5g5LTJP0Zd7ICDOAHBT51Or61otUVgT_qB1KjrdVfuF8vLoPhtRw0X_DJMxL-s7WRjf0Cv46L8iFXub2K7ZUnUtz8K-q0x_u5pCx0gDohXaFUUlGNkCut=w2400"
                  // src="assets/img/contact2.png"
                  alt="contact us"
                  className="img-fluid rounded-custom shadow-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
