import React from 'react';

const ServicesList = () => {
  return (
    <>
      <section
        // className={`feature-section ${
        //   paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        // } `}

        className="feature-section pt-60 pb-120"
      >
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div className="mb-4" data-aos="fade-up">
                <h2>Custom Web Development</h2>
                <p>
                  Solvaxion's web development team is skilled in creating
                  visually appealing and user-friendly websites that drive
                  business growth. We build responsive websites that look great
                  on any device, and our team is proficient in a variety of web
                  development technologies including HTML.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="pr-lg-4 mt-md-4 position-relative">
                <div
                  className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img
                    src="https://lh3.googleusercontent.com/WS6wMDPBCVwfxKcOIZpnewSUESHvXsHeRXl8CgKaMhTRjGB9KS-exZTAhPT-5ARHNK63wog7RUlM-SRCKeyUj--sDvtPvYM5q16c_ncDYQxzfjTm6vYr36sZAdtHG8i7pMjFZYqg=w2400"
                    // src="assets/img/customsoftware2.png"
                    alt=""
                    className="img-fluid rounded-custom shadow-sm"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section pt-60 pb-120">
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-1 mb-7 mb-lg-0">
              <div className="mb-4" data-aos="fade-up">
                <h2>Technology Consulting Services</h2>
                <p>
                  As a leading technology consulting firm based in Canada,
                  Solvaxion is well-equipped to help businesses of all sizes
                  navigate the constantly evolving landscape of technology. Our
                  team of experienced consultants can provide expert guidance on
                  a wide range of topics including cloud.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2">
              <div className="pr-lg-4 position-relative">
                <div className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto">
                  <img
                    src="https://lh3.googleusercontent.com/Iyd1zoo03fChmjIs6JNBxQIdSOvcZ3d-zzGnLRtpVnMp7qNvBiUD8tOz6lrAf0oQxwh5ogURk3Cc7NTbSkFYlFm73y0601QBOuO4cJh5N_fTkooEQ3wTktj6VDyQGrjAcJyBKRcv=w2400"
                    // src="assets/img/consulting.jpg"
                    alt=""
                    className="img-fluid rounded-custom shadow-sm"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section pt-60 pb-120">
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div className="mb-4" data-aos="fade-up">
                <h2>Data Analytics</h2>
                <p>
                  Data is a valuable asset for any business, but it's only
                  useful if you know how to turn it into actionable insights. At
                  Solvaxion, our team of data analysts is trained to extract
                  meaningful insights from large and complex datasets, helping
                  you make data-driven decisions.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="pr-lg-4 mt-md-4 position-relative">
                <div
                  className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img
                    src="https://lh3.googleusercontent.com/VQPsNaQ9wes3tNge2JT8ocqpdd_8mTJPfqC_lSfRMCO7BI_ENNrvzOK-SS-Aw4TCLh94klbaE__B2ZpDmhAZM8gZTxFgRUQqHpZR4MXgtsqsSifT-xHrhpO_0RYSfyMYhsUMQnko=w2400"
                    // src="assets/img/data.png"
                    alt=""
                    className="img-fluid rounded-custom shadow-sm"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section pt-60 pb-120">
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-1 mb-7 mb-lg-0">
              <div className="mb-4" data-aos="fade-up">
                <h2>Custom Software Development</h2>
                <p>
                  At Solvaxion, our team of experienced software developers is
                  dedicated to creating custom solutions that meet the unique
                  needs of your business. From developing standalone
                  applications to integrating with existing systems.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2">
              <div className="pr-lg-4 position-relative">
                <div className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto">
                  <img
                    src="https://lh3.googleusercontent.com/suB32-1AEg73-kRl03gkKVkIc9M-COgx7bGAseW_8dsotryATf4CEdumE8tiA9KfXHMJSkwwxDLEhkQ07YY7suvZu7wr6ciHKPOlVcbah3JuFY9kCqyMTzV4RT6G7J-OJmFivF63=w2400"
                    // src="assets/img/customsoftware.png"
                    alt=""
                    className="img-fluid rounded-custom shadow-sm"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-section pt-60 pb-120">
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div className="mb-4" data-aos="fade-up">
                <h2> Proprietary SaaS Software Development</h2>
                <p>
                  Solvaxion's proprietary SaaS (Software as a Service) software
                  development team is dedicated to creating innovative,
                  cloud-based solutions that help businesses streamline their
                  operations and drive growth. Our team has a wealth of
                  experience in developing SaaS software, and we have a deep
                  understanding of the unique challenges and opportunities that
                  come with this type of development.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="pr-lg-4 mt-md-4 position-relative">
                <div
                  className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img
                    src="https://lh3.googleusercontent.com/cwB65E1lT4hdm8YcsLrF-HZieJlx25zuW2cyzfEqSvkn8eYSZmS5DSqRzZT14D8nFAxVQRr0IVf3Rqk2Hu19Qjpo8tw38R-cLHsi_cp8rn87A6VuBESzV8H8PUuOkpvpnHOHvH1K=w2400"
                    // src="assets/img/saas.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesList;
